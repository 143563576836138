/* eslint-disable sort-keys */
import cx from "classnames";
import { graphql } from "gatsby";
import React, { ReactElement } from "react";
import Faq from "react-faq-component";
import { Helmet } from "react-helmet";

import {
    CallUs,
    SEO,
    SectionHead,
    SiteLayout,
    TasFollowButtons,
    TasReactButtons,
    TasShareButtons
} from "../components";
import { ServiceCard } from "../components/servicecard";
const styles = {
    bgColor: "transparent",
    titleTextColor: "white",
    rowTitleColor: "silver",
    rowContentColor: "grey",
    arrowColor: "yellow",
    rowContentPaddingBottom: 10,
    rowContentPaddingTop: 10,
    rowContentPaddingLeft: 10,
    rowContentPaddingRight:10
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};


const ContentPageTemplate = (props: any): ReactElement => {


    const { data, pageContext } = props;    
    
    const { site, faqjson, refservice } = data;
    const { edges } = faqjson;
   
    const nodes: Array<any> = edges;

    const refsrvs = (refservice ? refservice.edges : null);

    const entities: Array<any> = [];
    const rows :Array<any> = [];
    nodes.forEach(({ node }, i: number) => {
        node.faqs.map((obj: { q: string; a: string }, j: number) =>
        {
            entities.push({
                "@type": "Question",
                acceptedAnswer: {
                    "@type": "Answer",
                    text: obj.a
                },
                name: obj.q
            });
            rows.push({
                title: obj.q,
                content: obj.a
            });
        }
        );
    });

    const jsondata: any = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: entities
    };


    const faqdata = {
        title: "",
        rows: rows
    };

    return ( <SiteLayout data-testid={ "faq-site-layout" } className="text-gray-400 place-items-center">
        <Helmet>
            <title>FAQ : { pageContext.cat.name }</title>
            <script type="application/ld+json">{ JSON.stringify(jsondata) }</script>
        </Helmet>
        <SEO title={ "FAQ : "+ pageContext.cat.name } description={ pageContext.cat.desc  } />
    
       
        <div  className="flex  w-full grow items-stretch place-items-center justify-center p-4">
           
            <div className="m-10 p-4 w-10/12">
                <p className="pt-4 pb-4 text-4xl font-light text-white">
                    Frequently Asked Questions on 
                    <span className="text-gray-900 dark:text-amber-400"> &nbsp;{ pageContext.cat.name }</span>
                </p>
                <Faq 
                    data={ faqdata }
                    styles={ styles }
                    config={ config }
                />
            </div>
        </div>
        <div  className="flex  w-full grow  p-4">
            <section style={ { color: "white" } }>
                <p className="text-3xl font-light">
                    Read Other FAQs
                </p>
                <p className="flex flex-wrap flex-grow gap-1 mt-6">
                    {
                        pageContext.allfaqs.map((fcat:any, i:number) => 
                            (<a className="p-4 underline" key={ i } href={ "/"+ fcat.slug }> { fcat.name }</a> )
                        )
                    }
                </p>
            </section>
        </div>
        <div 
            className="font-light text-gray-400 grid grid-flow-row sm:grid-cols-1 auto-rows-max place-content-center w-full p-4">
            {
                pageContext.cat.relatedservices && 
                    <div 
                        className="flex flex-col flex-grow w-full flex-wrap place-items-start justify-center gap-2 p-4">
                        <p className="flex flex-row flex-grow ">
                            <span className="underline text-2xl">Related Services</span>
                        </p>
                        <div className="flex flex-wrap flex-row flex-grow  items-stretch place-items-center gap-4 mt-6">
                            { refsrvs.map((obj: any, index: number) => (
                                <ServiceCard data-testid={ index } key={ index } data={ obj.node }  siteroot={ site.siteMetadata.siteUrl }/>
                            )) }
                        </div>
                    </div>
            }
        </div>
        <><div className="flex flex-row  w-full  flex-col-1 grow items-stretch flex-wrap place-items-center justify-center gap-2 p-4">
            <p className="text-center text-xl md:text-2xl xl:text-2xl subpixel-antialiased font-light text-gray-400">
                Contact us now
            </p>
        </div><CallUs />
        </>
        <TasFollowButtons/>
    </SiteLayout>
    );
};
export default ContentPageTemplate;
export const pageQuery = graphql`
  query faqjsonquery($faqname: String, $relatedsrvs: [String]) {
    faqjson: allFaqsJson(filter: { cat: { src: { eq: $faqname } } }) {
      edges {
        node {
          faqs {
            a
            q
          }
          cat {
            name
            src
          }
        }
      }
    }
    site: site {
        siteMetadata {
          siteUrl
          title
          serviceurl
          projecturl
          blogurl
          author
        }
      }
    refservice: allMdx(
        filter: {frontmatter: { uid: {in: $relatedsrvs} }}
      ) {
        edges {
          node {
            id
            slug
            frontmatter {
              model
              title
              description
              uid
              heroimage {
                title
                file {
                  childImageSharp {
                    gatsbyImageData(width: 220)
                  }
                }
                thumb {
                  childImageSharp {
                    gatsbyImageData(width: 220)
                  }
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
  }
`;
